import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'

import LogoImage from "../../images/logos/logo.svg"
import LogoInvertImage from "../../images/logos/logo-white.svg"

const BrandLogo = styled(Link)`
	svg{
		width: 100%;
		max-width: 230px;
	}
	
`

export const Logo = ({invert}) => {

	return (
		<BrandLogo to="/">
			{invert ?
				<LogoInvertImage/>
			:	
				<LogoImage/>
			}
		</BrandLogo>
	)
}
