import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import styled from 'styled-components'
import LogoImage from "../../images/logos/logo.svg"

import { BreakPoint } from "../variables/"
import { Footer, Header, Nav, Social } from "./"
import { TextContent } from "../styles"


const Container = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
`
const Main = styled.main`
	flex-grow: 1;
`
const Farewell = styled.main`
	flex-grow: 1;
	background: #b3c8db;
	padding: 50px 15px;
	> div {
		max-width: 910px;
		margin: 0 auto;
	}
	svg {
		width: 100%;
		max-width: 200px;
		margin: 0 auto;
		margin-bottom: 40px;
	}
	h1 {
		margin-bottom: 20px;
	}
	@media ${BreakPoint.md} { 
		padding: 100px;
		svg {
			max-width: 260px;
			margin-bottom: 80px;
		}
		h1 {
			margin-bottom: 40px;
		}
	}
`

export const Page = ({ children }) => {

	return (

		<Container>
			<Farewell>
				<div>
					<LogoImage/>
					<TextContent center="true">
						<h1><strong>A Farewell from</strong><br/> Panelle Hair Studio</h1>
						<p>It is with a heavy heart that we announce the closure of Panelle Hair Studio after 35 wonderful years of serving our community. Due to unforeseen health reasons, we will be closing our doors for the final time on Saturday, 31st August.</p>
						<p>We’ve had the privilege of being here for over three decades, and we want to thank you all for your continued support and loyalty throughout the years. You have been at the heart of everything we do. You have trusted us with your hair, shared your lives with us, and supported our small business through the ups and downs. From the very beginning, our goal has been to create not just a place for haircuts and styles, but a welcoming space where everyone feels like family.</p>
						<p>Helen and I, would like to express just how much we will miss each and every one of you and we are filled with immense gratitude. You have not just been clients; you have been friends, confidants, and a constant source of joy and inspiration. It has been an honour to be part of your lives, and saying goodbye is incredibly difficult.</p>
						<p>We will be reaching out to all clients who have appointments scheduled after our closing date. We are also pleased to share that Sarah will be continuing her work independently from September. She will be based at the salon Three Little Birds, and you can book an appointment with her directly on 07890024599.</p>
						<p>Once again, thank you from the bottom of our hearts for your support, loyalty, and the many memories we’ve shared over the years. Panelle Hair Studio has been a special place because of you, and we will miss you.<br/><br/></p>
						<p><strong>Pani and Helen<br/> Panelle Hair Studio</strong></p>
					</TextContent>
				</div>
			</Farewell>
			{/* <Header/> */}
			{/* <Nav/> */}
			{/* <Main>
				{ children }
			</Main> */}
			{/* <Social/> */}
			{/* <Footer/> */}
		</Container>

	)
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
}
